import './styles/additional-pages/app.scss';

// start the Stimulus application
import './bootstrap';

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
// assets/bootstrap.js
import {startStimulusApp} from '@symfony/stimulus-bridge';
import PasswordVisibility from 'stimulus-password-visibility'

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./js/controllers',
    true,
    /\.(j|t)sx?$/
));

// register any custom, 3rd party controllers here
app.register('password-visibility', PasswordVisibility)
